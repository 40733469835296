import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import { createPinia } from 'pinia'
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const pinia = createPinia()

createApp(App).use(pinia).use(router).mount('#app')
