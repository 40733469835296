<template>
  <div class="hidden md:block bg-secondary text-white px-8 py-1 text-sm z-50 ">
    <div class="flex flex-wrap justify-between">
      <p>
        LITISS association d’avocats spécialistes en droit du travail
        rue Egide Van Ophem 40C - 1180 Uccle & rue du Petit Piersoulx 1 - 6041 Gosselies (Aéropôle).
        <a href="https://www.litiss.be" class="underline">www.litiss.be</a> BCE 0567 961 328
      </p>
      <router-link v-if="!isAuthenticated" :to="{name: 'login'}" class="underline">Login</router-link>
      <button v-else @click="logout" class="underline">Logout</button>
    </div>
  </div>
  <div class="block md:hidden bg-secondary text-xs text-white py-1 px-2 z-50">
    <div class="flex flex-col justify-around items-center">
      <p>LITISS association d’avocats spécialistes en droit du travail</p>
      <p>rue Egide Van Ophem 40C - 1180 Uccle & rue du Petit Piersoulx 1 - 6041 Gosselies (Aéropôle).</p>
      <p><a href="https://www.litiss.be" class="underline">www.litiss.be</a> BCE 0567 961 328</p>
      <router-link v-if="!isAuthenticated" :to="{name: 'login'}" class="underline">Login</router-link>
      <button v-else @click="logout" class="underline">Logout</button>
    </div>
  </div>
</template>

<script setup>
import {authStore} from "@/store/user";
import {computed} from "vue";
import router from "@/router";

const store = authStore()

const isAuthenticated = computed(() => store.isAuthenticated)

const logout = async () => {
  await store.logout()
  await router.push({name: 'home'})
}
</script>

<style scoped>

</style>
