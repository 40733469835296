<template>
  <div class="h-screen flex flex-col bg-gray-50">
    <NavComponent class="h-16"></NavComponent>
    <div class="flex-grow overflow-y-auto w-full" >
      <router-view/>
    </div>
    <FooterComponent></FooterComponent>
    <cookie-banner class="z-50" v-if="!cookiePreferencesSet" @cookie-preferences-set="cookiePreferencesSet = true" />
  </div>
</template>

<script setup>

import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {authStore} from "@/store/user";
import FooterComponent from "@/components/FooterComponent";
import NavComponent from "@/components/NavComponent";
import CookieBanner from "@/components/CookieBanner.vue";

const store = authStore()
const cookiePreferencesSet = ref(false)

const cookiePreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    if (cookiePreferences) {
      cookiePreferencesSet.value = cookiePreferences.functional && cookiePreferences.analytics;
    }

onBeforeMount(async () => {
  document.title = "Litiss - BDAT"
  await store.initializeStore()
  const token = store.token
  if (token) {
    axios.defaults.headers.common['Authorization'] = "Token " + token
    await store.whoAmI()
  } else {
    axios.defaults.headers.common['Authorization'] = ''
    store.isAuthenticated = false
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: white;
  background-color: #FFB866;
}
</style>
