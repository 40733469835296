import { defineStore } from 'pinia';
import axios from "axios";

export const authStore = defineStore('auth', {
    state: () => ({
        user: null,
        token: null,
        isAuthenticated: false,
        language: null,
        isLoading: false
    }),
    getters: {
    },
    actions: {
        initializeStore() {
            if (localStorage.getItem('t')) {
                this.token = localStorage.getItem('t')
                this.isAuthenticated = true
            } else {
                this.token = ''
                this.isAuthenticated = false
                axios.defaults.headers.common.Authorization = ""
            }
            if (localStorage.getItem('language')) {
                this.language = localStorage.getItem('language')
            } else {
                this.language = 'en'
                localStorage.setItem('language', 'en')
            }
        },
        async updateUser(id, formData) {
            await axios.put(`/api/users/profile/`, formData)
                .then(response => {
                this.user = response.data
            })
        },
        async updatePassword(formData) {
            await axios
                .post('/auth/users/set_password/', formData)
        },
        // Login
        async login(email, password) {
            axios.defaults.headers.common.Authorization = ""
            const formData = {
                email: email,
                password: password
            }
            await axios.post('/auth/token/login/', formData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(async response => {
                this.token = response.data.auth_token
                localStorage.setItem('t', this.token)
                axios.defaults.headers.common.Authorization = "Token " + this.token
                await this.whoAmI()
            })
        },
        async whoAmI() {
            await axios.get('/api/users/profile/', {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.user = response.data
                this.isAuthenticated = true
            }).catch(error => {
                axios.defaults.headers.common.Authorization = ""
                this.isAuthenticated = false
                this.user = null
                this.token = null;
                console.log(error)
            })
        },
        async logout() {
            await axios.post('/auth/token/logout/', {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(async response => {
                axios.defaults.headers.common.Authorization = ""
                this.isAuthenticated = false
                this.user = {}
                this.token = null;
                localStorage.removeItem('t')
            }).catch(error => {
                console.log(error)
            })
        },
        async pwdForgot (email) {
          await axios
              .post('/auth/users/reset_password/', {email: email})
        },
        async resetPwd(formData) {
          await axios
              .post("/auth/users/reset_password_confirm/", formData)
      },
        // Language
        setLanguage(lang) {
            this.language = lang
            localStorage.setItem('language', lang)
        },
        
    },
});
