<template>
  <div class="fixed bottom-0 flex flex-wrap w-full bg-secondary text-nav py-5 px-6 button-shadow">
    <div class="flex flex-col gap-2 text-sm md:text-base">
      <p class="">
        Nous utilisons des cookies afin d'améliorer votre expérience sur notre site. Veuillez choisir les types de cookies que vous souhaitez accepter:
      </p>
      <div class="flex flex-wrap gap-x-4">
        <div class="my-2">
          <input type="checkbox" id="functional" v-model="necessary" class="mr-2" disabled>
          <label for="functional">Cookies nécessaires</label>
        </div>
        <div class="my-2">
          <input type="checkbox" id="analytics" v-model="functional" class="mr-2">
          <label for="analytics">Cookies fonctionnels</label>
        </div>
        <div class="my-2">
          <input type="checkbox" id="functional" v-model="analytics" class="mr-2">
          <label for="functional">Cookies analytiques</label>
        </div>
        <div class="my-2">
          <input type="checkbox" id="analytics" v-model="marketing" class="mr-2">
          <label for="analytics">Cookies marketing</label>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-center gap-1 flex-grow items-center text-sm md:text-base">
      <button class="text-nav underline py-2 px-4 rounded h-fit" @click="denyCookies()">Tout refuser</button>
      <button class="text-nav underline py-2 px-4 rounded h-fit" @click="acceptCookies()">Enregistrer les préférences</button>
      <button class="btn-secondary" @click="acceptAllCookies()">Tout accepter</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      functional: false,
      analytics: false,
      necessary: true,
      marketing: false
    }
  },
  methods: {
    acceptCookies() {
      const cookiePreferences = {
        functional: this.functional,
        analytics: this.analytics,
        necessary: this.necessary,
        marketing: this.functional
      };
      this.saveCookies(cookiePreferences)
    },
    acceptAllCookies() {
      const cookiePreferences = {
        functional: true,
        analytics: true,
        necessary: true,
        marketing: true
      };
      this.saveCookies(cookiePreferences)
    },
    denyCookies() {
      const cookiePreferences = {
        functional: false,
        analytics: false,
        necessary: true,
        marketing: false
      };
      this.saveCookies(cookiePreferences)
    },
    saveCookies(cookiePreferences) {
      window.dataLayer.push({
        'cookieAnalytics': cookiePreferences.analytics,
      });
      localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
      this.$emit('cookie-preferences-set');
    }
  }
}
</script>
