<template>
  <div class="view flex flex-col gap-6 h-full items-end justify-end">
    <div class="absolute inset-0 w-full h-full">
      <img class="h-full w-full object-cover object-left" src="@/assets/images/image-1.jpg" alt="background-image" />
      <div class="absolute w-full h-full bottom-0 bg-gradient-to-t from-gray-900 to-transparent sm:opacity-90"></div>
    </div>

    <div class="flex flex-col gap-6 text-white z-10 container mt-60 sm:m-0 py-8 px-4 bg-nav bg-opacity-80 md:bg-transparent sm:rounded-t-lg overflow-y-auto">
      <h1 class="text-4xl text-left">Qui sommes-nous ?</h1>
      <div class="flex flex-col gap-2 text-justify w-full text-lg">
        <p>
          LITISS est un cabinet d’avocats spécialisés dans la question des accidents de travail, actif sur l’ensemble du pays. Nous sommes interpellés par le nombre de dossiers d’indemnisation d’incapacités permanentes partielles qui méritent la correction.
        </p>
        <p>Rarissimes sont les évaluations d’incapacité permanente qui résistent au recours médical ou judiciaire. L’écart entre le taux proposé par l’assurance et celui proposé par le médecin-expert encadré par les avocats est tel que ce n’est plus une marge d’erreur admissible.</p>
        <p>Nous avons voulu fournir un outil qui permette de mieux comprendre comment il faut apprécier une invalidité professionnelle.</p>
        <p>Nous le répétons :  ce test n’est pas un avis médico-légal. Il faut faire un travail sur-mesure. Nous sommes à votre disposition pour le faire avec vous.</p>
        <div class="flex flex-wrap gap-2 justify-between items-center">
          <p>
            En savoir plus sur <router-link :to="{name: 'about'}" class="underline">la méthode Litiss.</router-link>
          </p>
          <router-link :to="{name: 'info'}" class="btn-primary w-fit">Faire mon évaluation</router-link>
        </div>

      </div>

    </div>
  </div>
</template>

<script setup>
</script>
